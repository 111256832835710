import {aweApplication} from "../../awe";

// File viewer
aweApplication.directive('aweFileViewer',
  ['ServerData', 'AweUtilities', 'AweSettings', 'Component', 'Connection',
    function (serverData, Utilities, $settings, Component, Connection) {

      return {
        restrict: 'E',
        replace: true,
        templateUrl: function () {
          return serverData.getAngularTemplateUrl('fileViewer');
        },
        scope: {
          'widgetId': '@fileViewerId'
        },
        /**
         * Link function
         * @param {Object} scope Directive scope
         * @param {Object} elem Directive node
         */
        link: function (scope, elem) {
          // Init as component
          let  component = new Component(scope, scope.widgetId);
          if (!component.asComponent()) {
            // If component initialization is wrong, cancel initialization
            return false;
          }

          // Retrieve widget values
          let  stickBottom = false;
          let  initial = true;
          if (component.controller.parameters) {
            stickBottom = "stickBottom" in component.controller.parameters ? component.controller.parameters["stickBottom"] : stickBottom;
          }

          /**
           * Reload data from element
           */
          component.reload = function () {
            let  parameters = serverData.getFormValues();
            let  action = component.controller[$settings.get("serverActionKey")];
            let  target = component.controller[$settings.get("targetActionKey")];
            let  url = serverData.getGenericFileUrl(action, target);
            Connection.post(url, parameters).then((response) => {
              if (response.data && response.status === 201) {
                let  moveScroll = elem.scrollTop() + elem.height() >= elem[0].scrollHeight;
                scope.content = response.data;
                if (stickBottom) {
                  Utilities.stickToBottom(moveScroll, elem[0].scrollHeight, initial, elem);
                  initial = false;
                }
              }
            });

            // Check autorefresh
            component.checkAutoRefresh();
          };
        }
      };
    }]);
